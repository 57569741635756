<template>
    <div v-if="fields" class="contact-form">
        <div class="contact-form--container">
            <div v-show="!mailSent">
                <form class="form contact-form-standard" @submit.prevent="(event) => { handleFormSubmit(event) }">
                    <fieldset :disabled="disabled">
                    <input type="hidden" name="recaptcha_response" class="recaptcha_response" />
                    <input type="hidden" name="cf7-id" :value="fields.contact_form.ID" />
                    <div v-html="fields.contact_form_parsed"></div>
                    </fieldset>
                </form>
            </div>
            <div v-show="mailSent">
                <p class="text-success mb-0" v-html="mailSentMessage"></p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['acfFields', 'emailReplace', 'fieldReplace', 'formId'],

        data: function() {
            return {
                fields: this.acfFields,
                disabled: true,
                mailSent: false,
                mailSentMessage: '',
            }
        },

        mounted() {
            if (this.fields == null) {
                this.getContactForm().then(() => {
                    this.generateRecaptchaToken();
                    this.replaceFields();
                    this.getUserGeoLocation();
                });
            }
            else {
                this.generateRecaptchaToken();
                this.replaceFields();
                this.getUserGeoLocation();
            }
            this.disabled = false;

            $('[name="your-consent"]').on('click', function() {
                if ($(this).is(":checked")) {
                    $(this).parents('.form').find('[type="submit"]').attr("disabled", false);
                } else {
                    $(this).parents('.form').find('[type="submit"]').attr("disabled", true);
                }
            });
        },
        methods: { 
            getUserGeoLocation: function() {
                var fields = this.fields.contact_form_parsed;
                if(!fields || !fields.includes('country_phone_code')) { return; }

                $(".country_phone_code option:selected").removeAttr("selected");
                $(".country_phone_code option[data-countrycode='MT']").prop("selected", true);

                // Fetch user location by IP
                fetch('https://ipapi.co/json').then( res => res.json())
                .then(response => {
                    // Update the value in select to reflect user's location
                    if($(".country_phone_code")[0]) {
                        $(".country_phone_code option:selected").removeAttr("selected");
                        $(".country_phone_code option[data-countrycode='" + response.country_code + "']").prop("selected", true);
                    }
                })
                .catch((data, status) => { 
                    // Fallback
                    $(".country_phone_code option:selected").removeAttr("selected");
                    $(".country_phone_code option[data-countrycode='MT']").prop("selected", true);
                })
            },
            replaceFields: function() {
                //do we need to change the sender email ? 
                if (this.emailReplace != null) {
                    this.changeRecepientEmail(this.emailReplace);
                }

                //field replace needs to be {key, value}
                if (this.fieldReplace != null) {
                    if (this.fieldReplace.constructor == Array) {
                        var that = this;
                        this.fieldReplace.forEach(function(value, index, array) {
                            that.changeFieldValue(value);    
                        });
                    }
                    else if (this.fieldReplace.constructor == Object) {
                        this.changeFieldValue(this.fieldReplace);
                    }
                }
            
                //each contact form should have a url field
                this.changeFieldValue({
                    key: 'url',
                    value: window.location.href,
                });
            },     
            generateRecaptchaToken: function() {
                var that = this;

                grecaptcha.ready(function() {                
                    window.generateNewToken(that.$el, '.recaptcha_response');
                });
            },
            handleFormResponse: function(response) {
                switch(response.data.status) {
                    case 'validation_failed':
                        $(".contact-form-standard").find("span.wpcf7-form-control-wrap").removeClass("text-danger").hide();
                        $(".contact-form-standard").find(".form-control").removeClass("invalid");
                        $(".contact-form-standard").find(".checkbox-label").removeClass("invalid");

                        for (var index = 0; index < response.data.invalid_fields.length; ++index) {
                            //get element
                            var elementSpan = this.$el.querySelector(response.data.invalid_fields[index].into);
                            //assign value
                            elementSpan.value = response.data.invalid_fields[index].message;
                            //remove the display none from style (this will display the span element)
                            elementSpan.style.display = "";
                            elementSpan.classList.add("text-danger");

                            $(elementSpan).prev( ".form-control" ).addClass("invalid");
                            $(elementSpan).prev( ".checkbox-label" ).addClass("invalid");

                            $('html, body').animate({
                                scrollTop: ($('.contact-form-standard').offset().top - 120)
                            },500);
                        }  
                        this.disabled = false;
                    break;
                    case 'mail_sent':
                        //Email sent successfully
                        //Successful message can be found in the following property -> response.data.message
                        this.mailSent = true;
                        this.mailSentMessage = response.data.message;
                    break;
                    case 'mail_failed':
                        //Email not sent, Spam protection triggered
                        //error can be found in response.data.message (this can be shown to end user)
                    break;
                    case 'spam':
                        //Email not sent, Spam protection triggered
                        //error can be found in response.data.message (this can be shown to end user)
                    break;
                }
            },
            handleFormSubmit: function(event) {
                //ajax call
                var inputs = (event.target).querySelectorAll('input,textarea,select');
                var data = [];

                // Check for phone number and country code and save their 'name' attribue value
                var countryPhoneCodeName = null;
                var countryPhoneNumberName = null;
                
                //get all input elements and push to array name => value
                for (var index = 0; index < inputs.length; ++index) {

                    // Process checkboxes 
                    if ( inputs[index].getAttribute('type') == 'checkbox') {
                        if (inputs[index].checked) {
                            // concatenate multiple choices
                            if (data[inputs[index].getAttribute('name')]) {
                                let currentValue = data[inputs[index].getAttribute('name')];
                                data[inputs[index].getAttribute('name')] = currentValue + ' & ' + inputs[index].value;
                            } else {
                                data[inputs[index].getAttribute('name')] = inputs[index].value;
                            }
                        }
                    } else {

                        if(inputs[index].classList.contains('country_phone_code')) {
                            countryPhoneCodeName = inputs[index].getAttribute('name');
                        }

                        if(inputs[index].classList.contains('country_phone_number')) {
                            countryPhoneNumberName = inputs[index].getAttribute('name');
                        }

                        data[inputs[index].getAttribute('name')] = inputs[index].value;

                    }

                }                

                //serialize array ?
                let params = Object.assign({}, data);

                // Add country code number to phone number if the form has it
                if(params[countryPhoneCodeName] && params[countryPhoneNumberName]) {
                    params[countryPhoneNumberName] = params[countryPhoneCodeName] + ' ' + params[countryPhoneNumberName]; 
                }

                //ajax send to server
                this.disabled = true;

                axios.post('/api/send_email', params).then(response => {
                    
                    this.handleFormResponse(response);

                }).catch(error => {
                    // this.loaded = true;
                    // if (error.response.status === 422) {
                    //     this.errors = error.response.data.errors || error.response.data || {};
                    // }
                }).finally(() => {

                    this.generateRecaptchaToken();

                });
            },
            //not tested
            changeRecepientEmail: function(email) {
                if (this.$el.querySelector('[name="sender-email"]') != null) {
                    this.$el.querySelector('[name="sender-email"]').value = email;
                }
            },

            changeFieldValue: function(field) {
                if (this.$el.querySelector('[name="' + field.key + '"]') != null) {
                    this.$el.querySelector('[name="'+field.key+'"]').value = field.value;
                }
            },

            getContactForm: function() {
                return axios.get('/api/contact_form/' + this.formId)
                        .then(response => {
                            this.fields = {};
                            this.fields.contact_form_parsed = response.data.data.contact_form_parsed;
                            this.fields.contact_form = {};
                            this.fields.contact_form.ID = response.data.data.contact_form_id;
                        });
            },
        }
    }
</script>
