<template>
    <div class="listing--image-slider">
        <flickity v-if="images.length" @init="onInit" class="carousel-main shadow" ref="flickity" :options="carouselSettings">

            <div class="carousel-cell" v-if="youtubeVideos" v-for="video in youtubeVideos">
                <div class="image-holder" :style="'background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 40%), url('+getYoutubeVideoImage(video)+')'">     
                    
                    <!-- <iframe id="youtube-player" height="100%" :src="'https://www.youtube.com/embed/' + getYoutubeVideoIdFromUrl(video) + '?autoplay=1&mute=1&enablejsapi=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                    <youtube :player-vars="youtubePlayerVars" :video-id="getYoutubeVideoIdFromUrl(video)" ref="youtube" @ended="videoEnded"></youtube>

                </div>
            </div>
            <div class="carousel-cell carousel-cell--image" v-for="image in images">
                <div :style="'background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 40%), url('+image+')'">     
                </div>
            </div>
        </flickity> 


        <div class="image-slider--control-panel">
            <div class="image-slider--vr-tour" v-show="vrLink.length">
                <div class="d-flex align-items-center">
                    <icon-vr></icon-vr><a class="link-arrow-sm ml-10" :href="vrLink" target="_blank">{{ trans('misc.take_a_vr_tour') }}</a>
                </div>
            </div>

            <div class="image-slider--navigation" v-if="sliderLength > 1">

                <div class="navigation--dots">
                    <span>1</span>
                    <ol class="flickity-page-dots">
                        <li v-for="index in sliderLength" class="dot" :class="getCurrentIndex == index - 1 ? 'is-selected' : ''" :aria-current="getCurrentIndex == index - 1 ? 'step' : ''" :aria-label="'Page dot '+ index - 1"></li>
                    </ol>
                    <span>{{ sliderLength }}</span>
                </div>

                <div class="click-link ml-15" @click="previous"><icon-arrow-left /></div>
                <div class="click-link ml-5" @click="next"><icon-arrow-right /></div>

            </div>
        </div>


      </div>

</template>

<style>
.listing--image-slider .flickity-viewport {
    min-height: 100% !important;
}
</style>

<script>
    export default {
        props: ['images', 'vrLink', 'youtubeVideos'],

        data: function() {
            return {
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: false,
                    freeScroll: false,
                    autoPlay: this.youtubeVideos.length ? false : 2300, //should enable if no youtube
                    asNavFor: '.carousel-nav'
                },
                youtubePlayerVars: {
                    width: '100%',
                    resize : true,
                    mute : 1,
                    autoplay : 1,
                    frameborder : 0,
                    rel : 0,
                },
                getCurrentIndex: 0,
            }
        },

        computed: {
            player() {
                return this.$refs.youtube[0].player;
            },

            sliderLength() {
                return this.youtubeVideos.length + this.images.length;
            }
        },

        methods: {

            onInit() {
                var that = this;
                this.$refs.flickity.on('select', function () {
                    that.getCurrentIndex = that.$refs.flickity.selectedIndex();
                });

                if (this.youtubeVideos.length) {
                  this.$refs.flickity.on('change', (event) => {                  
                    // $('#youtube-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                    this.player.pauseVideo();
                  }); 
                }
            },

            next() {
                this.$refs.flickity.next();
                // this.$refs.flickity.stopPlayer();
            },
            
            previous() {
                this.$refs.flickity.previous();
                // this.$refs.flickity.stopPlayer();
            },

            getYoutubeVideoIdFromUrl(url) {
                // https://stackoverflow.com/a/8260383
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                var match = url.match(regExp);
                return (match && match[7].length == 11) ? match[7] : false;
            },

            getYoutubeVideoImage(url) {
                let videoId = this.getYoutubeVideoIdFromUrl(url);
                
                //https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                //return 'https://img.youtube.com/vi/'+videoId+'/0.jpg';

                //get image from youtube img (higher res)
                return 'https://img.youtube.com/vi/'+videoId+'/maxresdefault.jpg';
            },

            videoEnded() {
                this.player.stopVideo();
                this.next();
            }

            // openVideo: function() {
            //     this.$refs.youtubevideo.open();
            //     this.playVideo();
            // },

            // playVideo() {
            //     this.player.playVideo();
            // },

            // stopVideo() {
            //     this.player.stopVideo();
            // },
        }
    }
</script>