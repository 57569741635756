<template>

    <form class="form" @submit.prevent>

        <div class="row form-group">

            <div v-for="(listing, index) in this.data" class="col-12" :key="index">

                <div class="bg-white rounded p-20 shadow">

                    <div class="row">
                        <div class="col-12"><h4>{{ listing.PropertyTypeName + ' - ' + listing.TransactionTypeName + ' - ' + listing.TownName }}</h4></div>
                    </div>

                    <div class="row">
                        <div class="col-12"><p>{{ listing.Address }}</p></div>
                    </div>

                    <div class="row align-items-center">

                        <div class="col-lg-4 col-xxl-3">
                            <img :src="listing.DefaultPhoto" width="100%" />
                        </div>

                        <div class="col-lg-8 col-xxl-9">

                            <div class="row align-items-center">

                                <div class="col-md-6 py-10">
                                <label for="price">{{ trans('field-search-containers.price') }}</label>
                                <input v-model="formData[index].Price" type="number" name="price" class="form-control price">
                                <span v-if="errors && errors[index] && errors[index].Price" class="wpcf7-form-control-wrap price text-danger">{{ errors[index].Price }}</span>
                            </div>

                            <div class="col-md-6 py-10">
                                <label for="listingStatus">{{ trans('field-search-containers.listing_status') }}</label>
                                <select v-model="formData[index].ListingStatusId" name="listingStatus" class="form-control listingStatus" :class="errors[index].ListingStatusId ? 'invalid' : ''">
                                    <option v-for="(listingStatus) in formDropdowns.ListingStatuses" :value="listingStatus.Id">{{ listingStatus.Name }}</option>
                                </select>
                                <span v-if="errors && errors[index] && errors[index].ListingStatusId" class="wpcf7-form-control-wrap propertyType text-danger">{{ errors[index].ListingStatusId }}</span>
                            </div>

                            <div v-if="formData[index].TransactionTypeId != 1" class="col-md-6 py-10">
                                <label for="availableDate">{{ trans('field-search-containers.available_date') }}</label>
                                <date-picker v-model="formData[index].AvailableDate" valueType="format" input-class="form-control availableDate" value-type="date" lang="en-MT" format="DD/MM/YYYY"></date-picker>
                                <span v-if="errors && errors[index] && errors[index].AvailableDate" class="wpcf7-form-control-wrap availableDate text-danger">{{ errors[index].AvailableDate }}</span>
                            </div>

                            <div class="text-right py-10" :class="formData[index].TransactionTypeId == 1 ? 'col-md-12' : 'col-md-6'">
                                <button @click="submit(index)" type="submit" class="btn btn-primary">{{ trans('account.save') }}</button>
                            </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </form>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {

    components: { DatePicker },

    props: {
        data: Array,
        default: [],
    },

    data: function() {
        return {
            formDropdowns: {},
            formData: [],
            errors: [],
        }
    },

    methods: {
        /**
         * Submit data baed on index which corresponds to formData[index]
         * @param {*} index 
         */
        submit(index) {
            console.log( this.formData[index] );
        }
    },

    created() {
        var formData = [];

        this.data.forEach((listing, index) => {
            this.errors.push( {Price: '', AvailableDate: ''} );
            var newListing = listing;
            newListing.OwnerRequestedUpdate = true; //I fowner pressed submit, then the owner requested this update

            formData.push( newListing );
            if(listing.AvailableDate) { formData[index].AvailableDate = (new Date(listing.AvailableDate)).toLocaleDateString('en-MT'); } //Format date to d/M/Y
        });

        this.formData = formData;
        this.formDropdowns.ListingStatuses = this.$store.getters['staticTypes/StaticTypes']("ListingStatuses");
    },

}
</script>