<template>
    <div v-if="fields" class="contact-form">

        <div class="contact-form--container">

            <div v-show="!mailSent">

                <form class="form contact-form-enhanced" @submit.prevent="(event) => { handleFormSubmit(event) }">
                    
                    <fieldset :disabled="disabled">
                    <input type="hidden" name="recaptcha_response" class="recaptcha_response" id="recaptcha_response_token" />
                    <input type="hidden" name="cf7-id" :value="fields.contact_form.ID" />
                    <div v-html="fields.contact_form_parsed"></div>
                    <div class="form-group">
	            		<button name="form-send-message" id="form-send-message" class="btn-sm mb-15 btn-block" type="submit"><icon-edit />{{ trans('misc.send_message') }}</button>
                        <button name="form-view-phone-details" id="form-view-phone-details" class="btn-sm mb-15 btn-block" type="submit"><icon-phone />{{ trans('misc.view_associate_number') }}</button>
	            		
                        <div v-show="isViewPhoneDetails == true">
                            <div class="mt-20 pl-20">
                                <p>
                                    <strong>{{ trans('pages.mobile_number') }}:</strong> <a :href="'tel:' + prefix + mobileNumber">{{ prefix + mobileNumber }}</a>
                                    <br>
                                    <strong>{{ trans('pages.branch_phone') }}:</strong> <a :href="'tel:' + prefix + branchNumber">{{ prefix + branchNumber }}</a>
                                </p>
                            </div>
                        </div>
                        
                        <button v-if="!this.hideWhatsApp" name="form-chat-on-whatsapp" id="form-chat-on-whatsapp" class="btn-sm btn-block btn-whatsapp" type="submit"><icon-whatsapp />{{ trans('misc.whatsapp_link') }}</button>
                    </div>
                    </fieldset>

                </form>
            </div>

            <div v-show="mailSent">
            	<div v-if="viewDetails === true" class="mt-20">
                	<p>
                		<strong>{{ trans('pages.mobile_number') }}:</strong> <a :href="'tel:' + prefix + mobileNumber">{{ prefix + mobileNumber }}</a>
                		<br>
                		<strong>{{ trans('pages.branch_phone') }}:</strong> <a :href="'tel:' + prefix + branchNumber">{{ prefix + branchNumber }}</a>
                	</p>
                </div>
                <p v-if="mailSent == true" class="text-success mb-0" v-html="mailSentMessage"></p>
            </div>

        </div>
    </div>
</template>

<script>
    
    import 'event-submitter-polyfill';

    export default {
        props: ['env', 'acfFields', 'emailReplace', 'fieldReplace', 'formId', 'hideWhatsApp', 'titleForWhatsapp', 'formType'],

        data: function() {
            return {
                fields: this.acfFields,
                disabled: true,
                mailSent: false,
                mailSentMessage: '',
                prefix: '',
                branchNumber: '',
                mobileNumber: '',
                viewDetails: null,
                isWhatsapp: false,
                isViewPhoneDetails: false,
            }
        },

        computed: {
            whatsappLink: function() {
                return process.env.MIX_WHATSAPP_CHAT_LINK;
            }
        },

        mounted() {

            if (this.fields == null) {
                this.getContactForm().then(() => {
                    this.generateRecaptchaToken();
                    this.replaceFields();
                    this.getUserGeoLocation();
                });
            }
            else {
                this.generateRecaptchaToken();
                this.replaceFields();
                this.getUserGeoLocation();
            }

            this.disabled = false;

            $('[name="your-consent"]').on('click', function() {
                if ($(this).is(":checked")) {
                    $(this).parents('.form').find('[type="submit"]').attr("disabled", false);
                    $(this).parents('.form').find('[type="button"]').attr("disabled", false);
                } else {
                    $(this).parents('.form').find('[type="submit"]').attr("disabled", true);
                    $(this).parents('.form').find('[type="button"]').attr("disabled", true);
                }
            });
        },
        methods: { 
            getUserGeoLocation: function() {

                var fields = this.fields.contact_form_parsed;
                if(!fields || !fields.includes('country_phone_code')) { return; }

                $(".country_phone_code option:selected").removeAttr("selected");
                $(".country_phone_code option[data-countrycode='" + this.env.DEFAULT_COUNTRY_CODE + "']").prop("selected", true);

                // Fetch user location by IP
                fetch('https://ipapi.co/json').then( res => res.json())
                .then(response => {
                    // Update the value in select to reflect user's location
                    if($(".country_phone_code")[0]) {
                        $(".country_phone_code option:selected").removeAttr("selected");
                        $(".country_phone_code option[data-countrycode='" + response.country_code + "']").prop("selected", true);
                    }
                })
                .catch((data, status) => { 
                    if(!this.env.DEFAULT_COUNTRY_CODE) { return; }

                    // Fallback
                    $(".country_phone_code option:selected").removeAttr("selected");
                    $(".country_phone_code option[data-countrycode='" + this.env.DEFAULT_COUNTRY_CODE + "']").prop("selected", true);
                })
            },
            replaceFields: function() {
                //do we need to change the sender email ? 
                if (this.emailReplace != null) {
                    this.changeRecepientEmail(this.emailReplace);
                }

                //field replace needs to be {key, value}
                if (this.fieldReplace != null) {
                    if (this.fieldReplace.constructor == Array) {
                        var that = this;
                        this.fieldReplace.forEach(function(value, index, array) {
                            that.changeFieldValue(value);    
                        });
                    }
                    else if (this.fieldReplace.constructor == Object) {
                        this.changeFieldValue(this.fieldReplace);
                    }
                }
            
                //each contact form should have a url field
                this.changeFieldValue({
                    key: 'url',
                    value: window.location.href,
                });
            },     
            generateRecaptchaToken: function() {
                var that = this;

                grecaptcha.ready(function() {                
                    window.generateNewToken(that.$el, '.recaptcha_response');
                });
            },
            handleFormResponse: function(response, params) {
                switch(response.data.status) {
                    case 'validation_failed':

                        $(".contact-form-enhanced").find("span.wpcf7-form-control-wrap").removeClass("text-danger").hide();
                        $(".contact-form-enhanced").find(".form-control").removeClass("invalid");
                        $(".contact-form-enhanced").find(".checkbox-label").removeClass("invalid");

                        for (var index = 0; index < response.data.invalid_fields.length; ++index) {
                            
                            //get span element
                            var elementSpan = this.$el.querySelector(response.data.invalid_fields[index].into);

                            //assign value
                            elementSpan.value = response.data.invalid_fields[index].message;
                            //remove the display none from style (this will display the span element)
                            elementSpan.style.display = "";
                            elementSpan.classList.add("text-danger");
                            $(elementSpan).prev( ".form-control" ).addClass("invalid");
                            $(elementSpan).prev( ".checkbox-label" ).addClass("invalid");


                            $('html, body').animate({
                                scrollTop: ($('.contact-form-enhanced').offset().top - 120)
                            },500);
                        }  
                        this.disabled = false;
                    break;
                    case 'mail_sent':
                        //Email sent successfully
                        //Successful message can be found in the following property -> response.data.message
                        this.mailSent = true;
                        this.mailSentMessage = response.data.message;

                        this.getAgentContactDetails(params);
                    break;
                    case 'mail_failed':
                        //Email not sent, Spam protection triggered
                        //error can be found in response.data.message (this can be shown to end user)
                    break;
                    case 'spam':
                        //Email not sent, Spam protection triggered
                        //error can be found in response.data.message (this can be shown to end user)
                    break;
                }
            },
            handleFormSubmit: function(event) {
                this.isWhatsapp = false;

                //ajax call
                if (event.submitter.id === 'form-view-phone-details') 
                {
                	this.viewDetails = true;
                    this.isViewPhoneDetails = true;
                	document.getElementsByName("form-selected-action")[0].value = "Viewed the associate agent number";
                } 
                else 
                {
                	this.viewDetails = false;

                	if (event.submitter.id === 'form-chat-on-whatsapp') 
                    {
                		document.getElementsByName("form-selected-action")[0].value = "Chat on WhatsApp";
                		this.isWhatsapp = true;
                	} 
                    else if (event.submitter.id === 'form-send-message') 
                    { 
                		document.getElementsByName("form-selected-action")[0].value = "Filled in Form";
                	}
                }

                this.disabled = true;

                var inputs = (event.target).querySelectorAll('input,textarea,select');
                var data = [];
                data['isWhatsApp'] = this.isWhatsapp;

                // Check for phone number and country code and save their 'name' attribue value
                var countryPhoneCodeName = null;
                var countryPhoneNumberName = null;
                
                //get all input elements and push to array name => value
                for (var index = 0; index < inputs.length; ++index) {

                    // Process checkboxes 
                    if ( inputs[index].getAttribute('type') == 'checkbox') {
                        if (inputs[index].checked) {
                            // concatenate multiple choices
                            if (data[inputs[index].getAttribute('name')]) {
                                let currentValue = data[inputs[index].getAttribute('name')];
                                data[inputs[index].getAttribute('name')] = currentValue + ' & ' + inputs[index].value;
                            } else {
                                data[inputs[index].getAttribute('name')] = inputs[index].value;
                            }
                        }
                    } else {

                        if(inputs[index].classList.contains('country_phone_code')) {
                            countryPhoneCodeName = inputs[index].getAttribute('name');
                        }

                        if(inputs[index].classList.contains('country_phone_number')) {
                            countryPhoneNumberName = inputs[index].getAttribute('name');
                        }

                        data[inputs[index].getAttribute('name')] = inputs[index].value;
                    }

                } 

                //serialize array ?
                let params = Object.assign({}, data);

                // Add country code number to phone number if the form has it
                if(params[countryPhoneCodeName] && params[countryPhoneNumberName]) {
                    params[countryPhoneNumberName] = params[countryPhoneCodeName] + params[countryPhoneNumberName]; 
                }

                // If user clicked associate number -> do not send email
                if (event.submitter.id === 'form-view-phone-details')
                {
                    this.getAgentContactDetails(params);
                    this.disabled = false;
                    return;
                }

                //ajax send to server
                axios.post('/api/send_email', params)
                    .then(response => {
                    
                        this.handleFormResponse(response, params);
                        this.disabled = false;
                        
                    })
                    .catch(error => {
                        this.disabled = false;
                    })
                    .finally(() => {

                        this.generateRecaptchaToken();

                    });
            },
            //not tested
            changeRecepientEmail: function(email) {
                if (this.$el.querySelector('[name="sender-email"]') != null) {
                    this.$el.querySelector('[name="sender-email"]').value = email;
                }
            },

            changeFieldValue: function(field) {
                if (this.$el.querySelector('[name="' + field.key + '"]') != null) {
                    this.$el.querySelector('[name="'+field.key+'"]').value = field.value;
                }
            },

            getContactForm: function() {
                return axios.get('/api/contact_form/' + this.formId)
                        .then(response => {
                            this.fields = {};
                            this.fields.contact_form_parsed = response.data.data.contact_form_parsed;
                            this.fields.contact_form = {};
                            this.fields.contact_form.ID = response.data.data.contact_form_id;
                        });
            },

            getAgentContactDetails: function(params) {

            	let agentShortlink = $("input[name=agent-id]").val();

                // var windowReference = window.open();

            	return axios.get('/api/agents/' + agentShortlink + '/contact-details')
                        .then(response => {
                            this.prefix = response.data.data.Prefix;
                            this.branchNumber = response.data.data.WorkNumber;
                            this.mobileNumber = response.data.data.MobileNumber;
                        }).finally(() => {

		                    if (this.isWhatsapp) {

		                    	let whatsappUrl = '';

                                let formattedTitle = this.titleForWhatsapp ? encodeURIComponent('Hello! ') + encodeURIComponent(this.titleForWhatsapp) + '%0a%0a' : encodeURIComponent('Hello!') + '%0a%0a';

                                let submittedName = encodeURIComponent(params['your-name']);
                                let submittedEmail = encodeURIComponent(params['your-email']); 
                                let submittedPhone = encodeURIComponent(params['your-phone-number']);
                                let submittedMessage =  encodeURIComponent(params['your-message']);

                                let formattedSubmittedMessage = '';

                                if (submittedMessage) {
                                    formattedSubmittedMessage = encodeURIComponent('Message: ') + submittedMessage + '%0a';
                                }

                                let formattedPhone = '';

                                if (submittedPhone) {
                                    formattedPhone = encodeURIComponent('Phone: ') + submittedPhone + '%0a%0a';
                                }

                                if (this.formType == 'property') {

                                    let propertyReference = encodeURIComponent(params['listing-ref-id']);

                                    let whatsappFullText = formattedTitle +
                                        encodeURIComponent('Name: ') + submittedName + '%0a' +
                                        encodeURIComponent('Email: ') + submittedEmail + '%0a' +
                                        formattedSubmittedMessage +
                                        formattedPhone +
                                        encodeURIComponent('https://remax-malta.com/' + propertyReference);

                                    whatsappUrl = this.whatsappLink + this.prefix.replace('+','') + this.mobileNumber + '?text=' + whatsappFullText;

                                } else if (this.formType == 'agent') {

                                    let whatsappFullText = formattedTitle +
                                        encodeURIComponent('Name: ') + submittedName + '%0a' +
                                        encodeURIComponent('Email: ') + submittedEmail + '%0a' +
                                        formattedSubmittedMessage +
                                        formattedPhone;

                                    whatsappUrl = this.whatsappLink + this.prefix.replace('+','') + this.mobileNumber + '?text=' + whatsappFullText;

                                }
		                        
		                        // window.open(whatsappUrl, '_blank').focus();
                                // windowReference.location = whatsappUrl;
                                window.location.replace(whatsappUrl);

		                    }

		                });
            },
        },
    }
</script>
